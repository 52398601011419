import * as React from "react";

const locationMarker = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="h-8 w-8"
    viewBox="0 0 20 20"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
      clipRule="evenodd"
    />
  </svg>
);

const YearHighlight = (props) => {
  return (
    <div
      className={`flex flex-col gap-4 p-8 bg-blue-${props.backgroundTone} ${props.width} w-full text-white`}
    >
      <h4 className="flex font-bold text-4xl">{props.year}</h4>
      <h5 className="flex gap-2 py-4 items-center">
        <span>{locationMarker}</span>
        <span>Points of Presence: {props.presencePoints}</span>
      </h5>
      <div className="flex flex-col justify-between">
        <ul className="flex flex-col justify-between list-disc pl-5 text-sm gap-4">
          {props.highlights.map((highlight) => (
            <li key={highlight}>{highlight}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default YearHighlight;
