import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import smartLogo from "../images/smart.svg";
import ipxonLogo from "../images/ipxon-logo.svg";
import baremetalLogo from "../images/baremetal.svg";
import YearHighlight from "../components/YearHighlight";

const highlightsList = {
  2022: [
    "Nicaragua (Q1 2022)",
    "Honduras (Q1 2022)",
    "Bolivia (Q1 2022)",
    "Guatemala (Q2 2022)",
    "Salvador (Q2 2022)",
  ],
  2021: [
    "OrbitalBase, a new spin-off, is live from Lima, Perú & Quito Ecuador. Providing the best spots to link satellite constellations with the earth.",
    "We started operations in Santiago de Chile, Chile",
    "We started operations in Sao Paulo, Brazil",
    "We started operations in Rio de Janeiro, Brazil",
    "We started operations in Fortaleza, Brazil",
  ],
  2020: [
    "We started to provide services from our POP in the NAP of the Americas (NOTA), the Internet gateway from Latin America to the World.",
    "We expanded our operation in Panama City, Panama.",
    "We began operations in Buenos Aires, Argentina.",
    "We began operations in Guadalajara, Mexico.",
  ],
  2019: [
    "We started operations in Bogotá, Colombia.",
    "We started operations in Montevideo, Uruguay.",
    "We started operations in Quito, Ecuador.",
  ],
  2018: [
    "We began operations in Mexico City.",
    "We began operations in Lima, Perú.",
  ],
  2017: [
    "Conexum Inc. was born. We began operations from the US.",
    "Conexum Inc. acquires IPXON Networks, a web hosting company with operations in Argentina and Panama.",
    "Emiliano Gonzalez joins as CTO.",
    "We launched SmartPBX VOIP Services.",
    "Our Presence platform was born, we started offering cloud, bare-metal and connectivity services in a standardized way in Latin America.",
  ],
};

const WhoWeArePage = () => {
  const data = useStaticQuery(
    graphql`
      query {
        hero: file(relativePath: { eq: "who-we-are-image.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const hero = data.hero.childImageSharp.fluid;

  return (
    <main className="font-mulish w-full shadow-inner">
      <title>Conexum - Who we are</title>
      <div>
        <header id="header" className="bg-black h-1/5 transition-all">
          <Navbar />
        </header>
        <section className="relative">
          <div className="lg:absolute lg:inset-0">
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-hidden">
              <Img
                className="h-56 w-full object-cover lg:absolute lg:h-full"
                fluid={hero}
                objectFit="cover"
                objectPosition="50% 50%"
                alt="Conexum's cofounders Gabriel Poblete and Emiliano Gonzalez"
              />
            </div>
          </div>
          <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:mx-auto lg:py-32 lg:grid lg:grid-cols-2">
            <div className="lg:pr-8 flex justify-center">
              <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
                <h2 className="flex uppercase text-4xl gap-1 md:text-5xl font-bold text-blue-900 tracking-tighter">
                  <span>WHO</span>
                  <span className="font-light tracking-tighter text-blue-400">
                    WE ARE
                  </span>
                </h2>
                <h3 className="mt-8 text-lg text-gray-500 font-bold sm:mt-8 uppercase">
                  Our Philosophy
                </h3>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  We pursue continuous improvement with the ultimate goal of
                  providing excellence to our clients through our collaborators
                  and a large ecosystem that we have been developing since our
                  founding.
                </p>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  We believe that through knowledge, the human being can be free
                  and independent. We promote a business culture in which we
                  encourage our employees to grow both personally and
                  professionally.
                </p>
                <p className="mt-8 text-lg text-gray-500 sm:my-6">
                  As a company, we seek to achieve individual, group and
                  business satisfaction and fulfillment for our staff, clients
                  and the community that surrounds us. We provide tools and
                  create challenges to constantly motivate our team. We are
                  committed to creating spaces for each member to innovate and
                  be disruptive in their area, firmly demonstrating that we
                  value and reward the sense of belonging.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section>
          <section className="w-full flex flex-col sm:flex-row">
            <YearHighlight
              year="2022"
              highlights={highlightsList[2022]}
              presencePoints={"19 (Projected expansion by 2022)"}
              backgroundTone={900}
              markerTone={200}
              width="md:w-1/3"
            />

            <YearHighlight
              year="2021"
              highlights={highlightsList[2021]}
              presencePoints={14}
              backgroundTone={800}
              markerTone={200}
              width="md:w-1/3"
            />
            <YearHighlight
              year="2020"
              highlights={highlightsList[2020]}
              presencePoints={10}
              backgroundTone={700}
              markerTone={200}
              width="md:w-1/3"
            />
          </section>
          <section className="w-full flex flex-col sm:flex-row">
            <YearHighlight
              year="2019"
              highlights={highlightsList[2019]}
              presencePoints={8}
              backgroundTone={600}
              markerTone={600}
              width="md:w-1/3"
            />
            <YearHighlight
              year="2018"
              highlights={highlightsList[2018]}
              presencePoints={4}
              backgroundTone={500}
              markerTone={600}
              width="md:w-1/3"
            />
            <YearHighlight
              year="2017"
              highlights={highlightsList[2017]}
              presencePoints={2}
              backgroundTone={600}
              markerTone={400}
              width="md:w-1/3"
            />
          </section>
        </section>

        <section className="bg-white py-16">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <h2 className="flex justify-center gap-3 text-2xl md:text-4xl uppercase text-left font-thin tracking-tighter box-border text-blue-600">
              Our <span className="text-blue-400"> companies</span>
            </h2>
            <div className="mt-6 grid grid-cols-1 gap-2 md:grid-cols-3 lg:mt-8">
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100 items-center shadow-inner">
                <img className="h-8 flex" src={smartLogo} alt="SmartPBX logo" />
              </div>
              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100 items-center shadow-inner">
                <img className="h-10 flex" src={ipxonLogo} alt="IPXON logo" />
              </div>

              <div className="col-span-1 flex justify-center py-8 px-8 bg-gray-100 items-center shadow-inner">
                <img
                  className="h-8 flex"
                  src={baremetalLogo}
                  alt="Baremetal logo"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="container mx-auto flex flex-col md:flex-row justify-center p-4 py-8 md:py-16 gap-8 md:gap-16">
            <h2 className="flex flex-col justify-center text-2xl md:text-5xl uppercase text-left font-thin tracking-tighter box-border text-blue-600">
              Our <span className="text-blue-400"> mission</span>
            </h2>
            <div className="w-1/2">
              <ol className="flex flex-col gap-4 list-disc text-blue-400">
                <li>
                  <p className="text-gray-800">
                    Provide technological resources with high added value to
                    operate projects in Latin America, promoting innovation,
                    development and sustainability.
                  </p>
                </li>
                <li>
                  <p className="text-gray-800">
                    Support mission critical operations to guarantee the
                    continuity and proper functioning of the information systems
                    in the region.
                  </p>
                </li>
                <li>
                  <p className="text-gray-800">
                    Optimize technological resources of our clients, while also
                    providing them with access to a local diagnostic and support
                    agent.
                  </p>
                </li>
              </ol>
            </div>
          </div>
        </section> */}

        <section className="grid col-span-1 md:grid-cols-4 w-full bg-blue-900 text-white shadow-inner">
          <div className="col span-full lg:col-span-1 row-span-2 text-white py-15">
            <h2 className="flex md:flex-col justify-center text-4xl gap-4 md:gap-0 px-4 py-8 md:text-5xl uppercase  font-thin tracking-tighter box-border text-blue-500 ">
              Our <span className="text-blue-400"> mission</span>
            </h2>
          </div>
          <div className="flex flex-col p-8 text-left row-span-2 bg-blue-800 shadow-inner h-full w-full gap-2">
            <p className="text-gray-200">
              Provide technological resources with high added value to operate
              projects in Latin America, promoting innovation, development and
              sustainability.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left row-span-2 bg-blue-700  shadow-inner h-full w-full gap-2">
            <p className="text-gray-100">
              Support mission critical operations to guarantee the continuity
              and proper functioning of the information systems in the region.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left row-span-2 bg-blue-600 shadow-inner h-full w-full gap-2">
            <p className="text-gray-50">
              Optimize technological resources of our clients, while also
              providing them with access to a local diagnostic and support
              agents.
            </p>
          </div>
        </section>

        <section className="grid col-span-2 md:grid-cols-4 w-full bg-gray-900 text-white">
          <div className="col span-full lg:col-span-1 row-span-2 text-white">
            <h2 className="flex md:flex-col justify-center text-4xl gap-4 md:gap-0 px-4 py-8 md:text-5xl uppercase  font-thin tracking-tighter box-border text-blue-400 ">
              Our
              <span className="text-blue-500"> values</span>
            </h2>
          </div>
          <div className="flex flex-col p-8 text-left bg-gray-100 text-black gap-2">
            <h5 className="flex justify-start uppercase text-blue-900 font-bold text-2xl items-center gap-2">
              Innovation
            </h5>
            <p className="flex font-light tracking-tight text-base">
              Anticipating and adapting to change is crucial. Understanding
              global needs and problems before they happen is key for Conexum.
              Innovation is in our DNA.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left bg-gray-50  text-black gap-2">
            <h5 className="flex justify-start uppercase text-blue-700 font-bold text-2xl items-center gap-2">
              Human team
            </h5>
            <p className="flex font-light tracking-tight text-base">
              We would be nothing without the people who contribute their
              energy, knowledge, creativity and value to each solution in an
              honest and responsible way. Our company is built around our
              guarantee of a pleasant work environment, where our work team is
              happy and where we invest in providing ongoing training to each of
              our employees.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left bg-gray-100 text-black gap-2">
            <h5 className="flex justify-start uppercase text-blue-500 font-bold text-2xl items-center gap-2">
              Security
            </h5>
            <p className="flex font-light tracking-tight text-base">
              Data is the most precious thing in a company and we are committed
              to supporting each client, working to understand their business to
              protect and secure their information while maintaining the
              continuity of their operations under any circumstance.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left bg-gray-50  text-black gap-2">
            <h5 className="flex justify-start uppercase text-blue-400 font-bold text-2xl items-center gap-2">
              Ethics
            </h5>
            <p className="flex font-light tracking-tight text-base">
              We strongly believe in freedom of expression. Knowledge is freedom
              and therefore must be freely accessible. We support Open Source
              projects and base 99% of our infrastructure on them. We speak out
              against and say no to everything that damages the values of life,
              ethics and morals above all things.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left bg-gray-100 text-black gap-2">
            <h5 className="flex justify-start uppercase text-blue-600 font-bold text-2xl items-center gap-2">
              Confidentiality
            </h5>
            <p className="flex font-light tracking-tight text-base">
              We provide high value-added solutions, which requires us to
              immerse ourselves in all of our clients’ businesses, understanding
              from the core what drives each project. The trust our clients
              place in us is one of our most cherished values and we make sure
              to dedicate all of our resources to protecting their privacy.
            </p>
          </div>
          <div className="flex flex-col p-8 text-left bg-gray-50  text-black gap-2">
            <h5 className="flex justify-start uppercase text-blue-800 font-bold text-2xl items-center gap-2">
              Sustainability
            </h5>
            <p className="flex font-light tracking-tight text-base">
              Technologies have helped humanity to be more efficient and use
              fewer resources on a planet of 8 billion people, and this journey
              has only just begun. We are strongly committed to providing
              knowledge and infrastructure for entrepreneurial ecosystems that
              support the creation of disruptive systems, helping people to be
              more efficient and make better use of resources.
            </p>
          </div>
        </section>

        <section className="bg-white">
          <Footer />
        </section>
      </div>
    </main>
  );
};

export default WhoWeArePage;
